<template>
  <div class="mt-4 upcharge-setting w-100 payments-block">
    <div class="card payments-block__card">
      <div class="card-body position-relative">
        <div class="row">
          <div class="edit-container">
            <button
              variant="danger"
              class="create-button create-button--top"
              @click="toggleEdit"
            >
              {{ editing ? "Close" : "Edit" }}
            </button>
          </div>
          <div class="d-flex flex-column mx-auto">
            <img
              class="payments-block__image"
              src="/media/local/outgoing-icon.png"
              alt="Incoming Icon"
            />
            <div class="payments-block__text">Upcharge</div>
            <template v-if="!editing">
              <div class="payments-block__value">{{ amount }}</div>
            </template>
            <template v-else>
              <b-form @submit.prevent="editSetting" class="d-grid place-center">
                <b-input v-model="formAmount" type="number" step="0.001" />
                <button
                  type="submit"
                  class="mt-3 create-button"
                  :disabled="updating"
                  :class="`${updating ? 'create-button--disabled' : ''}`"
                >
                  Update
                </button>
              </b-form>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_UPCHARGE,
  UPDATE_UPCHARGE
} from "@/core/services/store/settings-us.module";

export default {
  name: "UpChargeUs",
  data() {
    return {
      amount: 0,
      formAmount: 0,
      id: null,
      editing: false,
      updating: false
    };
  },
  async mounted() {
    const { id, value } = await this.$store.dispatch(GET_UPCHARGE);
    this.amount = value * 100;
    this.formAmount = value * 100;
    this.id = id;
  },
  methods: {
    toggleEdit: function() {
      this.editing = !this.editing;
      if (this.editing === false) {
        this.formAmount = this.amount;
      }
    },
    editSetting: async function() {
      console.log("here");
      this.updating = true;
      const { id, formAmount } = this;
      console.log(
        "file: UpCharge.vue | line 73 | editSetting:function | id",
        id
      );
      const value = parseFloat((formAmount / 100).toFixed(2));
      const response = await this.$store.dispatch(UPDATE_UPCHARGE, {
        id,
        value
      });
      this.updating = false;
      if (!response.error) {
        this.amount = value * 100;
        this.editing = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
